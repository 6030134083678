import React, { useState } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import Button from "../atoms/Button";
const gisDomains = [
  "Environmental Management",
  "Urban Planning",
  "Agriculture",
  "Transportation",
  "Utilities",
  "Telecommunications",
  "Real Estate",
  "Public Health",
  "Disaster Management",
  "Natural Resources",
  "Other",
];

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    country: {},
    aoi: "",
    message: "",
    category: "",
  });

  const [v, setV] = useState(0);

  const options = countryList().getData();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, country: selectedOption });
  };
  
  
  const handleSubmit = async(e) => {
    
    e.preventDefault();    

    try{
      const response = await fetch('https://gj-mapsolutions.e-connectsolutions.com/api/contact', {
        method : 'POST',
        headers : {
          "Content-Type" : "application/json",
        },
        body : JSON.stringify(formData)
      })
      if(response.ok) alert("Contact submitted successfully");
      else alert("Failed to submit contact details");
    }catch(error){
      console.error('Error submitting contact details: ', error);
    }
  }
  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#1F2937",
      borderColor: "#555",
      color: "white",
      padding: "0.5rem",
      borderRadius: "0.375rem",
      minHeight: "42px",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1F2937",
      color: "white",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused ? "#333" : isSelected ? "#444" : "#1F2937",
      color: "white",
      ":active": {
        backgroundColor: "#555",
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "white",
    }),
  };
  const handleCategoryChange = (value) => {
    setFormData({ ...formData, category: value });
  };
  return (
    
    <form
      onSubmit={handleSubmit}
      className="flex flex-col w-full max-w-xl mx-auto space-y-10 p-6"
    >
      <input
        type="text"
        name="name"
        placeholder="Your Name"
        required
        value={formData.name}
        onChange={handleChange}
        className="p-3 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <input
        type="email"
        name="email"
        placeholder="Work Email"
        value={formData.email}
        required
        onChange={handleChange}
        className="p-3 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <input
        type="text"
        name="company"
        required
        placeholder="Company Name"
        value={formData.company}
        onChange={handleChange}
        className="p-3 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <Select
        options={options}
        value={formData.country}
        onChange={handleCountryChange}
        styles={customStyles}
        required
        placeholder="Select Country"
        className="rounded text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <select
        name="aoi"
        required
        value={formData.aoi}
        onChange={handleChange}
        className="p-3 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="" disabled>
          Industry
        </option>
        {gisDomains.map((domain) => (
          <option key={domain} value={domain}>
            {domain}
          </option>
        ))}
      </select>

      <textarea
        name="message"
        placeholder="Your Message"
        required
        onChangeCapture={(e) => {
          setV(e.target.value.length);
        }}
        value={formData.message}
        onChange={handleChange}
        maxLength={200}
        className="p-3 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
      />
      <p className="text-white text-right">Characters Left: {200 - v}</p>

      <fieldset className="space-y-4">
        <legend className="text-lg">
          Please tick the box that best applies to you:
        </legend>
        {[
          { value: "customer", label: "I am a potential customer" },
          { value: "partner", label: "I am a potential partner" },
          { value: "supplier", label: "I am a potential supplier" },
          { value: "other", label: "Other" },
        ].map(({ value, label }) => (
          <label key={value} className="flex items-center" >
            <input
              type="radio"
              name="category"
              value={value}
              checked={formData.category === value}
              onChange={() => handleCategoryChange(value)}
              className="hidden"
              
            />
            {formData.category === value ? (
              <FaCheckCircle size={20} className="text-primary-blue mr-2" />
            ) : (
              <FaRegCircle size={20} className="text-primary-blue mr-2" />
            )}
            {label}
          </label>
        ))}
      </fieldset>
      
      <div className="w-full justify-center" type="submit">
        <Button
          className="px-6 py-3 w-full bg-primary-blue rounded-full hover:border-bg-blue-800 hover:bg-blue-800  hover:border-white transition ease-in-out duration-300"
          textStyle="text-white text-lg md:text-xl"
          text="Submit"
        />
      </div>

    </form>
  );
};

export default ContactForm;

// remote sensing, xpgeo, oi, dl, aerial sphere, atomic maps, esri, geodyne
// www is not working
// check deployment process


// import React, { useState } from "react";
// import Select from "react-select";
// import countryList from "react-select-country-list";
// import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
// import Button from "../atoms/Button";

// const gisDomains = [
//   "Environmental Management",
//   "Urban Planning",
//   "Agriculture",
//   "Transportation",
//   "Utilities",
//   "Telecommunications",
//   "Real Estate",
//   "Public Health",
//   "Disaster Management",
//   "Natural Resources",
//   "Other",
// ];

// const ContactForm = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     company: "",
//     country: {},
//     aoi: "",
//     message: "",
//     category: "",
//   });

//   const [v, setV] = useState(0);

//   const options = countryList().getData();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleCountryChange = (selectedOption) => {
//     setFormData({ ...formData, country: selectedOption });
//   };

//   const handleCategoryChange = (value) => {
//     setFormData({ ...formData, category: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const subject = `Contact Request by ${formData.name}`;
//     const body = `
//       Name: ${formData.name},
//       Email: ${formData.email},
//       Company: ${formData.company},
//       Country: ${formData.country.label},
//       Area of Interest: ${formData.aoi},
//       Category: ${formData.category},
//       Message: ${formData.message}
//     `;// Format body for mailto

//     const mailtoLink = `mailto:info@gj-mapsolutions.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

//     window.location.href = mailtoLink;
//   };

//   const customStyles = {
//     control: (styles) => ({
//       ...styles,
//       backgroundColor: "#1F2937",
//       borderColor: "#555",
//       color: "white",
//       padding: "0.5rem",
//       borderRadius: "0.375rem",
//       minHeight: "42px",
//     }),
//     menu: (styles) => ({
//       ...styles,
//       backgroundColor: "#1F2937",
//       color: "white",
//     }),
//     singleValue: (styles) => ({
//       ...styles,
//       color: "white",
//     }),
//     option: (styles, { isFocused, isSelected }) => ({
//       ...styles,
//       backgroundColor: isFocused ? "#333" : isSelected ? "#444" : "#1F2937",
//       color: "white",
//       ":active": {
//         backgroundColor: "#555",
//       },
//     }),
//     placeholder: (styles) => ({
//       ...styles,
//       color: "white",
//     }),
//   };

//   return (
//     <form
//       onSubmit={handleSubmit}
//       className="flex flex-col w-full max-w-xl mx-auto space-y-10 p-6"
//     >
//       <input
//         type="text"
//         name="name"
//         placeholder="Your Name"
//         required
//         value={formData.name}
//         onChange={handleChange}
//         className="p-3 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
//       />
//       <input
//         type="email"
//         name="email"
//         placeholder="Work Email"
//         value={formData.email}
//         required
//         onChange={handleChange}
//         className="p-3 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
//       />
//       <input
//         type="text"
//         name="company"
//         required
//         placeholder="Company Name"
//         value={formData.company}
//         onChange={handleChange}
//         className="p-3 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
//       />
//       <Select
//         options={options}
//         value={formData.country}
//         onChange={handleCountryChange}
//         styles={customStyles}
//         required
//         placeholder="Select Country"
//         className="rounded text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
//       />
//       <select
//         name="aoi"
//         required
//         value={formData.aoi}
//         onChange={handleChange}
//         className="p-3 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
//       >
//         <option value="" disabled>
//           Industry
//         </option>
//         {gisDomains.map((domain) => (
//           <option key={domain} value={domain}>
//             {domain}
//           </option>
//         ))}
//       </select>

//       <textarea
//         name="message"
//         placeholder="Your Message"
//         required
//         onChangeCapture={(e) => {
//           setV(e.target.value.length);
//         }}
//         value={formData.message}
//         onChange={handleChange}
//         maxLength={200}
//         className="p-3 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
//       />
//       <p className="text-white text-right">Characters Left: {200 - v}</p>

//       <fieldset className="space-y-4">
//         <legend className="text-lg">
//           Please tick the box that best applies to you:
//         </legend>
//         {[
//           { value: "customer", label: "I am a potential customer" },
//           { value: "partner", label: "I am a potential partner" },
//           { value: "supplier", label: "I am a potential supplier" },
//           { value: "other", label: "Other" },
//         ].map(({ value, label }) => (
//           <label key={value} className="flex items-center">
//             <input
//               type="radio"
//               name="category"
//               value={value}
//               checked={formData.category === value}
//               onChange={() => handleCategoryChange(value)}
//               className="hidden"
//             />
//             {formData.category === value ? (
//               <FaCheckCircle size={20} className="text-primary-blue mr-2" />
//             ) : (
//               <FaRegCircle size={20} className="text-primary-blue mr-2" />
//             )}
//             {label}
//           </label>
//         ))}
//       </fieldset>

//       <div className="w-full justify-center">
//         <Button
//           className="px-6 py-3 w-full bg-primary-blue rounded-full hover:border-bg-blue-800 hover:bg-blue-800 hover:border-white transition ease-in-out duration-300"
//           textStyle="text-white text-lg md:text-xl"
//           text="Submit"
//         />
//       </div>
//     </form>
//   );
// };

// export default ContactForm;
