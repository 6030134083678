import React, { useEffect } from "react";
import Header from "../molecules/Header";
import Footer from "../molecules/Footer";
import Button from "../atoms/Button";

const Careers = () => {
  
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const handleSubmitClick = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    // http://www.gj-mapsolutions.com/backend/api/contact
    try {
      const response = await fetch('https://gj-mapsolutions.e-connectsolutions.com/api/formsubmit', {
        method: 'POST',
        body: formData,
      });
      
      if (response.ok) {
        alert('Form submitted successfully!');
      } else {
        console.error('Failed to submit form data and resume');
        alert('Failed to submit form. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form and resume:', error);
      alert('Error submitting form. Please try again.');
    }
    
  };

  return (
    <div className="bg-black">
      <Header />
      <div className="flex flex-col mb-12">
        <div className="flex-grow mt-32">
          <div id="formsection" className="flex flex-col sm:flex-row ">
            <div className="sm:w-1/2 w-full flex items-center justify-center">
              <img
                src="images/location.svg"
                alt="UDP2"
                className="sm:h-[500px] sm:w-auto p-4 rounded-3xl"
              />
            </div>
            <div className="sm:w-1/2 w-full px-6 py-4 flex items-center justify-center">
              <form
                onSubmit={handleSubmitClick}
                className="sm:w-5/6 w-full"
                encType="multipart/form-data"
              >
                <h2 className="text-3xl sm:text-5xl text-white mb-6">Join Our Team</h2>
                <div className="flex flex-col sm:flex-row mb-4 w-full">
                  <div className="sm:w-1/2 w-full pr-0 sm:pr-2 mb-2 sm:mb-0">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-400 ml-4 mb-1"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      required
                      className="p-3 pl-4 w-full text-white bg-gray-800 border border-gray-800 rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary-blue"
                    />
                  </div>
                  <div className="sm:w-1/2 pl-0 sm:pl-2">
                    <label htmlFor="lastName"className="block text-sm font-medium text-gray-400 ml-4 mb-1"> Last Name </label>
                    <input type="text" id="lastName"
                      name="lastName"
                      required
                      className="p-3 pl-4 w-full text-white bg-gray-800 border border-gray-800 rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary-blue"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-400 ml-4 mb-1"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    required
                    className="p-3 pl-4 w-full text-white bg-gray-800 border border-gray-800 rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary-blue"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-400 ml-4 mb-1"
                  >
                    Add a note for the recruiter
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    required
                    className="p-3 pl-4 w-full text-white bg-gray-800 border border-gray-800 rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary-blue"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="resume"
                    className="block text-sm font-medium text-gray-400 ml-4 mb-1"
                  >
                    Upload Resume (PDF)
                  </label>
                    <input
                      type="file"
                      id="resume"
                      name="resume"
                      accept=".pdf"
                      required
                      className="p-3 pl-4 w-full text-white bg-gray-800 border border-gray-800 rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary-blue"
                    />
                </div>
                
                <Button
                  className="px-6 py-3 w-full bg-primary-blue rounded-3xl hover:border-bg-blue-800 hover:bg-blue-800  hover:border-white transition ease-in-out duration-300"
                  textStyle="text-white text-lg md:text-xl"
                  text="Submit"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Careers;

// import React, { useEffect } from "react";
// import Header from "../molecules/Header";
// import Footer from "../molecules/Footer";
// import Button from "../atoms/Button";

// const Careers = () => {

//   useEffect(() => {
//     window.scrollTo(0,0);
//   }, []);

//   const handleSubmitClick = (e) => {
//     e.preventDefault();

//     const formData = new FormData(e.target);
//     const firstName = formData.get('firstName');
//     const lastName = formData.get('lastName');
//     const phone = formData.get('phone');
//     const message = formData.get('message');

//     // Create a mailto link with the predefined template
//     const emailBody = `
//     NOTE: Please attach your resume to this email only in pdf format.
    
//     ${message}
    
    
//     Sincerely,
//     ${firstName} ${lastName}
    
    
//       Contact : ${phone}
//     If you have any further questions or require additional information, please don't hesitate to reply to this email. 
//     `;
//     const mailtoLink = `mailto:info@gj-mapsolutions.com?subject=Job Application by ${firstName} ${lastName}&body=${encodeURIComponent(emailBody)}`;
//     window.location.href = mailtoLink;
//   };

//   return (
//     <div className="bg-black">
//       <Header />
//       <div className="flex flex-col mb-12">
//         <div className="flex-grow mt-32">
//           <div id="formsection" className="flex flex-col sm:flex-row ">
//             <div className="sm:w-1/2 w-full flex items-center justify-center">
//               <img
//                 src="images/location.svg"
//                 alt="UDP2"
//                 className="sm:h-[500px] sm:w-auto p-4 rounded-3xl"
//               />
//             </div>
//             <div className="sm:w-1/2 w-full px-6 py-4 flex items-center justify-center">
//               <form
//                 onSubmit={handleSubmitClick}
//                 className="sm:w-5/6 w-full"
//                 encType="multipart/form-data"
//               >
//                 <h2 className="text-3xl sm:text-5xl text-white mb-6">Join Our Team</h2>
//                 <div className="flex flex-col sm:flex-row mb-4 w-full">
//                   <div className="sm:w-1/2 w-full pr-0 sm:pr-2 mb-2 sm:mb-0">
//                     <label
//                       htmlFor="firstName"
//                       className="block text-sm font-medium text-gray-400 ml-4 mb-1"
//                     >
//                       First Name
//                     </label>
//                     <input
//                       type="text"
//                       id="firstName"
//                       name="firstName"
//                       required
//                       className="p-3 pl-4 w-full text-white bg-gray-800 border border-gray-800 rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary-blue"
//                     />
//                   </div>
//                   <div className="sm:w-1/2 pl-0 sm:pl-2">
//                     <label htmlFor="lastName" className="block text-sm font-medium text-gray-400 ml-4 mb-1">Last Name</label>
//                     <input
//                       type="text"
//                       id="lastName"
//                       name="lastName"
//                       required
//                       className="p-3 pl-4 w-full text-white bg-gray-800 border border-gray-800 rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary-blue"
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-4">
//                   <label
//                     htmlFor="phone"
//                     className="block text-sm font-medium text-gray-400 ml-4 mb-1"
//                   >
//                     Phone Number
//                   </label>
//                   <input
//                     type="tel"
//                     id="phone"
//                     name="phone"
//                     required
//                     className="p-3 pl-4 w-full text-white bg-gray-800 border border-gray-800 rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary-blue"
//                   />
//                 </div>
//                 <div className="mb-4">
//                   <label
//                     htmlFor="message"
//                     className="block text-sm font-medium text-gray-400 ml-4 mb-1"
//                   >
//                     Add a note for the recruiter
//                   </label>
//                   <textarea
//                     id="message"
//                     name="message"
//                     rows="4"
//                     required
//                     className="p-3 pl-4 w-full text-white bg-gray-800 border border-gray-800 rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary-blue"
//                   ></textarea>
//                 </div>
//                 <Button
//                   className="px-6 py-3 w-full bg-primary-blue rounded-3xl hover:border-bg-blue-800 hover:bg-blue-800 hover:border-white transition ease-in-out duration-300"
//                   textStyle="text-white text-lg md:text-xl"
//                   text="Submit"
//                 />
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default Careers;
