import React, { useEffect, useRef, useState } from "react";
import Header from "../molecules/Header";
import Footer from "../molecules/Footer";
import Lottie from "lottie-react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";


const DeepLearningSection = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [currentVideo, setCurrentVideo] = useState("images/object-detection-final.gif");
  const [i, setI] = useState(0);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleVideoSwitch = () => {
    setI((prevI) => (prevI === 0 ? 1 : 0));
    setCurrentVideo((prevVideo) =>
      prevVideo === "images/object-detection-final.gif"
        ? "images/road-extraction-2.gif"
        : "images/object-detection-final.gif"
    );
  };

  const items = [
    {
      title: "Advanced Object Detection",
      description:
        "Utilize cutting-edge deep learning models to accurately detect and classify objects in your spatial data, enhancing your analytical capabilities.",
    },
    {
      title: "Automated Data Processing",
      description:
        "Streamline your workflows with automated data processing powered by deep learning, reducing manual effort and improving accuracy.",
    },
    {
      title: "Custom Model Development",
      description:
        "Our team develops custom deep learning models tailored to your specific GIS needs, ensuring optimal performance and accuracy.",
    },
    {
      title: "Innovative Analytics",
      description:
        "Gain new insights with innovative deep learning-driven analytics, empowering you to make informed decisions based on your spatial data.",
    },
    {
      title: "Integration with GIS Workflows",
      description:
        "Seamlessly integrate deep learning models with workflows, enhancing your existing GIS infrastructure with advanced capabilities.",
    },
  ];

  return (
    <div className="bg-black text-gray-900 px-8 md:px-24">
      <p className="text-white text-3xl md:text-5xl mt-4 mb-4 lg:mb-10">Deep Learning</p>
      <p className="text-2xl md:text-3xl mb-4">
        <strong className="text-primary-blue">Revolutionizing GIS with Deep Learning</strong>
      </p>
      <div className="mb-12">
        <p className="text-lg text-gray-400">
          Harness the power of deep learning to transform geospatial data into actionable insights. Elevate traditional GIS from basic analysis to cutting-edge intelligence with deep learning.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          {items.map((item, index) => (
            <div key={index} className="bg-black cursor-pointer">
              <div
                className="flex items-center justify-between py-4 border-b border-gray-700"
                onClick={() => toggleExpand(index)}
              >
                <h3
                  className={`text-lg flex flex-row items-center gap-4 ${
                    expandedIndex === index ? "text-primary-blue" : "text-white"
                  }`}
                >
                  {item.title}
                </h3>
                <div className="text-white text-3xl">
                  {expandedIndex === index ? "−" : "+"}
                </div>
              </div>
              <div
                className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                  expandedIndex === index ? "max-h-screen" : "max-h-0"
                }`}
                style={{ maxHeight: expandedIndex === index ? "500px" : "0" }}
              >
                <div className="py-4">
                  <p className="text-gray-300">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="relative flex justify-center items-center w-full">
          <div className="rounded-3xl md:m-3 md:ml-5">
            <img
              src={currentVideo}
              alt="current-video"
              className="rounded-3xl w-full object-cover"
            />
          </div>
          <button
            onClick={handleVideoSwitch}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-primary-blue text-white rounded-full p-2 hover:bg-blue-600 transition duration-300 animate-pulse"
          >
            {i === 0 ? (
              <FaChevronRight size={16} className="text-white" />
            ) : (
              <FaChevronLeft size={16} className="text-white" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};



const OrientedImagery = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  let expanded = false;
  const toggleExpand = (index) => {
    expanded = true;
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const items = [
    {
      title: "Versatile Viewing Options",
      description:"Experience our software service for both 2D and 3D content, tailored to your specific needs, providing a comprehensive view of your data.",
    },
    {
      title: "Dynamic Map Integration",
      description:"Seamlessly integrate imagery with dynamic map views, automatically adjusting to show image extents and positions as you navigate.",
    },
    {
      title: "Enhanced Image Quality",
      description:"Utilize built-in enhancement tools to improve image clarity and detail, providing more accurate data analysis.",
    },
    {
      title: "Interactive Feature Editing",
      description:"Create and modify features directly within the imagery viewer with real-time updates and visualization on the map.",
    },
    {
      title: "Sophisticated Querying",
      description:"Perform queries based on the current view and filters, including time-based data, to extract meaningful insights from your imagery.",
    },
  ];

  return (
    <div className="bg-black text-gray-900 px-8 md:px-24">
      <p className="text-white pb-4 text-3xl md:text-5xl mt-4 lg:pb-8">
        Oriented Imagery
      </p>
      <p className="text-2xl md:text-3xl mb-4">
        <strong className="text-primary-blue">
          Transforming Data into Insightful Solutions
        </strong>
      </p>
      <div className="mb-12">
        <p className="text-lg text-gray-400">
          Leverage our expertise in oriented imagery to deliver cutting-edge
          software solutions. We enhance how you interact with and analyze
          spatial data, ensuring that your projects achieve their full
          potential.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          {items.map((item, index) => (
            <div key={index} className="bg-black cursor-pointer">
              <div
                className="flex items-center justify-between py-4 border-b border-gray-700"
                onClick={() => toggleExpand(index)}
              >
                <h3
                  className={`sm:text-xl text-lg  flex flex-row items-center gap-4 ${
                    expandedIndex === index ? "text-primary-blue" : "text-white"
                  }`}
                >
                  {item.title}
                </h3>
                <div className="text-white text-3xl">
                  {expandedIndex === index ? "−" : "+"}
                </div>
              </div>
              <div
                className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                  expandedIndex === index ? "max-h-screen" : "max-h-0"
                }`}
                style={{ maxHeight: expandedIndex === index ? "500px" : "0" }}
              >
                <div className="py-4">
                  <p className="text-gray-300">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center items-center w-full">
          <div className="rounded-3xl md:m-3 md:ml-5">
            <img
              src="images/oi-finished.gif"
              alt="final-video"
              className="rounded-3xl w-full h-auto object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Expertise = () => {
  const arcgisRef = useRef(null);
  const orientedRef = useRef(null);
  const deepLearningRef = useRef(null);
  const [x, setX] = useState(null);
  useEffect(() => {
    fetch('animations/globe.json').then(r => r.json()).then((d) => setX(d))
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash.substring(1);
    if (hash === "/expertise#arcgis" && arcgisRef.current) {
      const sec = document.getElementById("arcgis");
      window.scrollTo({
        top: sec.offsetTop - 80,
        behavior: "smooth",
      });
    } else if (hash === "/expertise#oriented-imagery" && orientedRef.current) {
      const sec = document.getElementById("oriented");
      window.scrollTo({
        top: sec.offsetTop - 80,
        behavior: "smooth",
      });
    } else if (hash === "/expertise#deep-learning" && deepLearningRef.current) {
      const sec = document.getElementById("dl");
      window.scrollTo({
        top: sec.offsetTop - 80,
        behavior: "smooth",
      });
    }
  }, [window.location]);

  return (
    <div className="bg-black text-gray-400 ">
      <Header />
      <div id="arcgis" ref={arcgisRef}>
        <div className="pt-20">
          <div className="flex flex-col md:flex-row items-center px-8 md:pl-24 pt-10">
            <div className="md:w-2/3">
              <h1 className="text-white text-3xl md:text-5xl">
                Know your GIS:{" "}
                <strong className="text-primary-blue font-bold">ArcGIS</strong>
              </h1>
              <p className="text-left pt-8 md:pr-24 text-justify text-lg">
                ArcGIS is a leading geographic information system (GIS) platform{" "}
                <strong className="text-white">developed by Esri</strong>,
                designed to help organizations visualize, analyze, and manage
                spatial data. With its robust tools for mapping, spatial
                analytics, and data management, ArcGIS empowers businesses
                across various industries to make informed decisions based on
                location intelligence. Whether it's urban planning,
                environmental monitoring, or infrastructure management, ArcGIS
                provides the insights needed to solve complex challenges. With a
                commitment to excellence and innovation, we aim to push the
                boundaries of what's possible with GIS technology.
              </p>
            </div>
            <Lottie animationData={x} className="md:ml-6 mt-6 md:mt-0 w-80 h-80 md:w-96 md:h-96"/>
          </div>
        </div>
      </div>
      <div>
     
      </div>
      <div className="mt-20 md:mt-20"></div>
      <div id="oriented" ref={orientedRef}>
        <OrientedImagery />
      </div>
      <div className="mt-20 md:mt-40"></div>
      <div id="dl" ref={deepLearningRef}>
        <DeepLearningSection />
      </div>
      

      <div className="mt-20 sm:mt-40"></div>
      <Footer />
    </div>
  );
};

export default Expertise;