import React from "react";

function Button({ className, text, onClick, textStyle, ...rest }) {
  return (
    <div>
      <button onClick={onClick} className={className ? className : 'px-4 py-2 bg-blue-500 rounded-xl'}>
        <p className={`${textStyle}`} >{text ? text : "Button"}</p>
      </button>
    </div>
  );
}

export default Button;
