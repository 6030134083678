
import React, { useState } from "react";
import Button from "../atoms/Button";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [logoSrc, setLogoSrc] = useState("images/blue-logo-white-text.svg");
  const [black, setBlack] = useState(false);

  const nav = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickHome = () => {
    nav("/home");
  };
  const handleClickServices = () => {
    nav("/services");
  };
  const handleClickAbout = () => {
    nav("/company");
  };
  const handleClickExpertise = () => {
    nav("/expertise");
  }
  const handleExploreClick = () => {
    nav("/home");
  };
  const handleClickContact = () => {
    nav("/contact")
  }

  return (
    <header
      onMouseEnter={() => {
          setLogoSrc("images/blue-logo-black-text.svg")
          setBlack(true);
        }
      }
      onMouseLeave={() => {
          setLogoSrc("images/blue-logo-white-text.svg")
          setBlack(false);
        }
      }
      className="fixed top-0 left-0 w-full sm-custom:pl-[80px] sm-custom:pr-[80px] sm-custom:pt-[10px] bg-header-blue hover:bg-white sm-custom:pb-[10px] bg-header-blue z-50 transition-all hover:ease-in-out hover:duration-[400ms]"
    >
      <div className="relative flex flex-col sm-custom:flex-row  items-center justify-between text-[#008dd2] text-lg sm-custom:text-2xl p-4 sm-custom:p-2">
        <div className="flex items-center justify-between w-full sm-custom:w-auto sm-custom:mb-0">
          <div
            onClick={handleExploreClick}
            className="flex flex-row hover:cursor-pointer items-center "
          >
            <img src={logoSrc} alt="Map" className="h-[40px] hover:scale-115 transform  transition hover:duration-150 sm-custom:mr-2" />
            {/* <p className="text-white font-defTreb font-semibold ">GJ Map Solutions</p> */}
            
          </div>
          <div className="sm-custom:hidden">
            <button onClick={toggleMenu} className="focus:outline-none">
              <svg
                className="w-8 h-8 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <nav className="hidden sm-custom:flex w-full sm-custom:w-auto">
          <ul className="flex flex-col sm-custom:flex-row w-full sm-custom:w-auto gap-4 sm-custom:gap-6 text-center">
            <li className="hover:underline ">
              <Button
                onClick={handleClickHome}
                className="bg-none hover:text-black "
                text="Home"
                textStyle="text-xl font-bold"
              />
            </li>
            <li className="hover:underline ">
              <Button
                onClick={handleClickExpertise}
                className="bg-none hover:text-gray-900 "
                text="Expertise"
                textStyle="text-xl font-bold"
              />
            </li>
            <li className="hover:underline ">
              <Button
                onClick={handleClickServices}
                className="bg-none hover:text-gray-900 "
                text="Services"
                textStyle="text-xl font-bold"
              />
            </li>
            <li className="hover:underline ">
              <Button
                onClick={handleClickAbout}
                className="bg-none hover:text-gray-900 "
                text="About"
                textStyle="text-xl font-bold"
              />
            </li>
            <li className="hover:underline ">
              <Button
                onClick={handleClickContact}
                className="bg-none hover:text-gray-900 "
                text="Contact"
                textStyle="text-xl font-bold"
              />
            </li>
          </ul>
        </nav>
        <div
          className={`fixed inset-0 bg-slate-950 bg-opacity-100 flex flex-col items-center justify-center z-50 transition-opacity duration-300 ${
            showMenu
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 focus:outline-none"
          >
            <svg
              className="w-8 h-8 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          <ul className="flex flex-col gap-10 text-center text-2xl">
            <li>
              <Button
                onClick={handleClickHome}
                className="bg-none text-white"
                text="Home"
              />
            </li>
            <li>
              <Button
                onClick={handleClickAbout}
                className="bg-none text-white"
                text="About Us"
              />
            </li>
            <li>
              <Button
                onClick={handleClickServices}
                className="bg-none text-white"
                text="Services"
              />
            </li>
            <li>
              <Button
                onClick={handleClickExpertise}
                className="bg-none text-white"
                text="Expertise"
              />
            </li>
            <li>
              <Button
                onClick={handleClickContact}
                className="bg-none text-white"
                text="Contact"
              />
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
