import React from "react";
import Button from "../atoms/Button";
import { useNavigate } from "react-router-dom";
import { FaLinkedin, FaInstagram, FaMapMarkerAlt, FaPhone, FaTwitter, FaTwitterSquare, FaMailchimp, FaEnvelope, FaLink, FaAt, FaShareSquare, FaShareAltSquare, FaShareAlt, FaShare } from "react-icons/fa";
import { FaEnvelopeCircleCheck } from "react-icons/fa6";

const Footer = () => {
  const nav = useNavigate();

  const handleButtonClick = () => {
    window.location.href = "mailto:info@gj-mapsolutions.com";
  };

  const handleCareerClick = () => {
    nav("/careers");
  };

  return (
    <div className="pt-10 px-8 pb-10 flex flex-col  sm:p-20 bg-[#0b0e10]">
      <div className="flex flex-col sm:flex-row justify-between gap-6 sm:gap-10">
      
        <div className="flex flex-col justify-start items-start sm:w-1/3">
          <div className="flex flex-row  mb-8">
            <img
              src="images/whole-white-logo.svg"
              alt="GJ Map Solutions Logo"
              className="w-1/3 mr-5"
            />
            <div className="flex justify-center items-center">
            <div className="w-[1px] bg-gray-500 h-3/4 mr-4"></div>
            </div>
            <div className="flex justify-center items-center">
              <p className="text-base text-white italic uppercase font-semibold">Where maps shape your vision</p>
            </div>
          </div>
          <p className="text-gray-400 text-left font-medium text-base sm:text-lg mb-6 sm:mb-8">
          <strong>GJ Map Solutions</strong> is an India-based company specializing in software development and consulting services within the GIS domain, leveraging Esri's ArcGIS platform to deliver innovative and tailored geospatial solutions.
          </p>
        </div>

        <div className="flex sm:justify-center align-center">
          <div className="">
            <div className="flex items-center ">
              <FaAt className="text-white mr-4" size={20} />
              <p className="text-lg sm:text-xl text-white">
                Email
              </p>
            </div>
            <div
              onClick={handleButtonClick}
              className="text-lg mt-4 text-gray-400 hover:underline cursor-pointer"
            >
              info@gj-mapsolutions.com
            </div>
            <div className="flex items-center mt-6">
              <FaShare className="text-white mr-4" size={20} />
              <p className="text-lg sm:text-xl text-white">
                Follow us
              </p>
            </div>
           
            <div className="flex gap-6 mt-4">
              <a
                href="https://www.linkedin.com/company/gj-map-solutions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin
                  className="text-gray-400 hover:text-blue-500 cursor-pointer"
                  size={26}
                />
              </a>
              {/* <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram
                  className="text-gray-400 hover:text-purple-500 cursor-pointer"
                  size={26}
                />
              </a> */}
              <a href="https://x.com/gj_mapsolutions" target="_blank" rel="noopener noreferrer">
                <img src="images/x.svg" className="w-[26px] w-[26px] hover:text-gray-900"/>
              </a>
            </div>
          </div>
        </div>

        <div className="flex sm:justify-center align-center">
        <div className="">
          <div className="flex items-center mb-4">
            <FaMapMarkerAlt className="text-white mr-4" size={20} />
            <p className="text-lg sm:text-xl text-white">
              Office
            </p>
          </div>
          <p className="text-gray-400 text-sm sm:text-lg">
            104, Padmini Marg
            <br />
            Udaipur, Rajasthan, 313001
            <br />
            India
          </p>
        </div>
        </div>
        <div className="flex sm:justify-center align-center">
        <div className="mb-12 md:mb-0">
          <div className="flex items-center mb-4">
            <FaLink className="text-white mr-4" size={20} />
            <p className="text-lg sm:text-xl text-white ">
              Popular Links
            </p>
          </div>
          <Button
              onClick={handleCareerClick}
              className="text-gray-400 text-lg hover:underline"
              text={"Careers"}
            />  
        </div>
        </div>

      </div>
      <div>
        <div className="h-[2px] w-full bg-gray-500"></div>
        <div className="text-end pt-[20px]"><p className="text-gray-400">Copyright @ 2024 GJ Map Solutions</p></div>
      </div>
    </div>
  );
};

export default Footer;
