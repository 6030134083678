import React from "react";
import { FaLinkedin, FaTwitter } from "react-icons/fa";

const Footer2 = () => {
  return (
    <div className="bg-black text-white flex flex-col md:flex-row  sm:p-12">
      <div className="w-full md:w-1/4 flex justify-center items-center mb-8 md:mb-0">
        <div
          id="left-container-for-image"
          className="w-48 h-48 flex justify-center items-center flex-col "
        >
          <img
            src="images/sir2.jpeg"
            className="w-48 h-48 rounded-full object-cover"
            alt="Kushagra Patel"
          />
          <div className="grid grid-cols-1 pt-6">
            <a
              href="https://www.linkedin.com/in/kushagra-p-09560364"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin
                className="text-white hover:text-blue-500 cursor-pointer"
                size={30}
              />
            </a>
          </div>
        </div>
      </div>
      <div
        id="right-container-for-text"
        className="flex flex-col w-full md:w-3/4 justify-center px-8 pb-8"
      >
        <div id="name" style={{ color: "#008dd2" }} className="mb-2 text-4xl">
          Kushagra Patel
        </div>
        <div id="title" className="text-xl mb-4">
          Founder
        </div>
        <div
          id="desc"
          className="w-full text-justify text-base md:text-lg"
        >
          Kushagra Patel is a seasoned GIS professional with over a decade of
          experience in the geospatial industry. He
          is the architect behind Esri’s Oriented Imagery technology, providing
          solutions for inspection workflows, asset management, data management,
          and data commercialization. Passionate about leveraging
          geospatial data, he utilizes advanced deep learning technologies to
          transform imagery into actionable insights, driving innovation and
          efficiency for his clients. He is dedicated to advancing the
          field of GIS and remote sensing, empowering businesses to unlock the
          full potential of their geospatial data.  
        </div>
      </div>
    </div>
  );
};

export default Footer2;
