import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

import Header from "../molecules/Header";
import Footer2 from "../molecules/Footer2";
import Footer from "../molecules/Footer";
// import Lottie from "lottie-react";

const CardItem = ({ heading, description }) => (
  <div className="bg-header-blue transition-shadow transition-transform duration-300 ease-in-out hover:shadow-smooth-blue rounded-3xl p-6">
    <h2 className="text-2xl font-semibold mb-2 text-white">{heading}</h2>
    <p className="text-lg leading-relaxed  text-gray-400">
      {description}
    </p>
  </div>
);

const About = () => {
  // const [ani, setAni] = useState(null);
  // useEffect(() => {
  //   fetch("animations/analysis.json").then(d => d.json()).then(d => setAni(d));
  // }, [])
  const images = [
    "images/drone-survey-2.jpg",
    "images/urban-nav.jpg",
    "images/drone-6.jpg",
    "images/inspection.jpg",
    "images/landsat-3.jpg",
    "images/drone-5.jpg",
    "images/code-2.jpg",
  ];

  let winwidth = useRef(window.screen.width);
  useEffect(()=>{
    winwidth.current = window.screen.width;
  },[window.screen.width])

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <div className="bg-black min-h-screen text-white ">
      <Header />
      <div className="px-6 mt-12 md:px-12 lg:px-24 sm:mt-0 sm:pt-0">
        <div className="flex flex-col md:flex-row items-center mb-16 md:h-screen  ">
          <div className="md:w-1/2 text-left sm:text-center md:text-left">
            <h1 className="text-4xl lg:text-6xl text-white mb-6 mt-12 ">Our Story</h1>
            <p className="md:text-lg text-justify text-gray-300 sm:mb-8 leading-relaxed sm:pr-10 sm:mr-10">
              Welcome to{" "}
              <strong className="animated-gradient-text font-semibold text-xl">
                GJ Map Solutions
              </strong>
              <br />
              <br />
              Our journey began in 2021 when Kushagra, after nearly a decade
              with Esri, returned to India with a mission to empower businesses
              through GIS. With a strong business background and a legacy of
              successful family ventures, his passion for business was innate.
              Driven to assist clients globally, he
              combined his business acumen and problem-solving skills in ArcGIS
              to establish <strong>GJ Map Solutions</strong>. <br></br>
              <br></br>Our goal is to help clients leverage ArcGIS technology to
              tackle real-world challenges, providing innovative GIS solutions
              and exceptional services.
            </p>
          </div>
          <div className="w-full sm:w-1/2 mt-12  flex justify-center items-center">
            <Swiper
              spaceBetween={1}
              slidesPerView={winwidth.current > 630 ? 2 : 1}
              centeredSlides={true}
              loop={true}
              navigation={true}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              modules={[Navigation, Autoplay]}
              className="mySwiper"
            >
              {images.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={image}
                    alt={`slide-${index}`}
                    className="rounded-3xl shadow-xl object-cover h-[450px] w-[95%] mx-auto"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div className="text-center md:text-left p-4">
            <h1 className="text-3xl md:text-4xl font-bold">
              Perfect Analysis for Your Needs
            </h1>
            <p className="text-lg mt-4">
              Unlock insights and drive smarter decisions with our cutting-edge analysis tools.
            </p>
          </div>
          <div className="p-4">
            <Lottie animationData={ani} />
          </div>
        </div> */}

        <div className="grid mt-[100px] gap-8 mb-16 md:grid-cols-3">
          <CardItem
            heading={"Our Vision"}
            description="Our vision is to be at the forefront of GIS innovation, providing solutions that empower our clients to harness the full potential of their data."
          />
          <CardItem
            heading={"Mission"}
            description="We empower organizations with innovative GIS solutions, leveraging our expertise in GIS technology. We are committed to solving complex challenges and delivering tailored services that drive success in the geospatial industry."
          />
          <CardItem
            heading={"Building on Trust"}
            description="Having one of the highest client satisfaction rates is a testament to our quality of solutions. We deliver more than what is expected of us, keeping the trust of our clients."
          />
        </div>
      </div>
      <Footer2 />
      <Footer />
    </div>
  );
};

export default About;
