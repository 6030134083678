import React, { useRef } from "react";
import TCard from "../molecules/TCard";
import Header from "../molecules/Header";
import Footer from "../molecules/Footer";
import Button from "../atoms/Button";
import { useNavigate } from "react-router-dom";
import { FaBrain } from "react-icons/fa";

const Home = () => {
  const nav = useNavigate();
  const tCardRef = useRef(null);
  const intro = useRef(null);

  const handleNav = () => {
    nav("/contact");
  };

  const handleWork = () => {
    nav("/services");
  };
  const scrollToIntro = () => {
    if (intro.current) intro.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleKnowMore = (section) => {
    nav(`/expertise#${section}`);
  };
  return (
    <div className="flex flex-col bg-black overflow-x-hidden ">
      <Header />
      <div className="lg:h-screen relative">
        <div className="absolute inset-0 overflow-hidden">
          <video autoPlay muted loop controls={false} className="w-full h-full object-cover">
            <source src="video/home-page-2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 bg-black opacity-80"></div>
        </div>
        <div className="relative z-10 flex flex-col justify-center items-center h-screen">
          <div className="flex flex-col items-center justify-center pt-20 px-4">
            <h1 className="text-white text-center text-2xl md:text-5xl font-bold pb-[50px]">
              Empowering
              <span className="text-primary-blue ml-2 mr-2">Informed</span>
              Decisions
              <br /> with
              <span className="text-primary-blue ml-2 mr-2">Location</span>
              Intelligence
              <br />
            </h1>
            <h3 className="text-white text-lg md:text-3xl text-center px-2">
              Gear up Your GIS Capabilities with our Consulting and Software
              Development Services
            </h3>
          </div>
          <div className="flex flex-row p-2 md:flex-row items-center justify-center mt-12 mb-12 gap-6">
            <Button
              className="px-6 py-3 bg-primary-blue rounded-full hover:border-bg-blue-800 hover:bg-blue-800 hover:border-white transition ease-in-out duration-300"
              textStyle="text-white text-lg md:text-xl"
              text="Contact us"
              onClick={handleNav}
            />
            <Button
              className="px-6 py-3 border-solid rounded-full hover:border-bg-blue-800 hover:bg-blue-800 border-[1px] hover:border-white transition ease-in-out duration-300"
              textStyle="text-white text-lg md:text-xl"
              text="Check out our services"
              onClick={handleWork}
            />
          </div>
          <button
            onClick={scrollToIntro}
            className="text-white text-3xl mt-10 animate-bounce invisible sm:visible"
            
          >
            ↓
          </button>
        </div>
      </div>



      <div ref={intro} className="">
        <h1 className=" mt-12 mb-12 text-white text-center text-3xl sm:text-4xl md:text-5xl sm:mt-24 md:mt-32 sm:mb-16 md:mb-24">
          Explore our expertise
        </h1>

        <div className="flex justify-center items-center px-8 sm:px-6 lg:px-8">
          <div className="bg-black grid grid-cols-1 gap-6 sm:gap-8 md:grid-cols-2 lg:grid-cols-3 w-full max-w-7xl">
            <div
              onClick={() => handleKnowMore("arcgis")}
              className="px-8 py-6 bg-header-blue transition-shadow duration-300 rounded-3xl shadow-lg hover:shadow-smooth-blue"
            >
              <div className="flex items-center space-x-4">
                <h1 className="text-white text-xl sm:text-2xl md:text-3xl">
                  ArcGIS
                </h1>
                <img
                  src="images/arcgis.svg"
                  className="w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 object-contain"
                  alt="ArcGIS"
                />
              </div>
              <p className="text-gray-400 pt-4 text-base sm:text-lg">
                A comprehensive platform for geographic information system (GIS)
                technology, enabling advanced spatial analysis, data management,
                and mapping to support decision-making across diverse
                industries.
              </p>
              <div
                onClick={() => handleKnowMore("arcgis")}
                className="flex items-center text-white hover:cursor-pointer mt-4"
              >
                <span className="mr-2">Know More</span> →
              </div>
            </div>

            <div
              onClick={() => handleKnowMore("oriented-imagery")}
              className="px-8 py-6 bg-header-blue transition-shadow duration-300 rounded-3xl shadow-lg hover:shadow-smooth-blue"
            >
              <div className="flex items-center space-x-4">
                <h1 className="text-white text-xl sm:text-2xl md:text-3xl">
                  Oriented Imagery
                </h1>
                <img
                  src="images/oriented-imagery-widget.svg"
                  className="w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 object-contain"
                  alt="Oriented Imagery"
                />
              </div>
              <p className="text-gray-400 pt-4 text-base sm:text-lg">
                A specialized technology that integrates images with spatial
                data, allowing for precise visualization, analysis, and
                interpretation of environments from any angle or perspective.
              </p>
              <div
                onClick={() => handleKnowMore("oriented-imagery")}
                className="flex items-center text-white hover:cursor-pointer mt-4"
              >
                <span className="mr-2">Know More</span> →
              </div>
            </div>

            <div
              onClick={() => handleKnowMore("deep-learning")}
              className="px-8 py-6 bg-header-blue transition-shadow duration-300 rounded-3xl shadow-lg hover:shadow-smooth-blue"
            >
              <div className="flex items-center space-x-4">
                <h1 className="text-white text-xl sm:text-2xl md:text-3xl">
                  Deep Learning
                </h1>
                <FaBrain className="w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 text-primary-blue" />
              </div>
              <p className="text-gray-400 pt-4 text-base sm:text-lg">
                Deep learning enhances spatial data analysis by enabling precise
                object detection, classification, and predictive analytics,
                transforming raw data into actionable insights.
              </p>
              <div
                onClick={() => handleKnowMore("deep-learning")}
                className="flex items-center text-white hover:cursor-pointer mt-4"
              >
                <span className="mr-2">Know More</span> →
              </div>
            </div>
          </div>
        </div>
      </div>

   
      {/* <div className="mt-24"></div> */}

      <div ref={tCardRef}>
        <TCard />
      </div>

      {/* <div className="pt-80 sm:pt-0"></div> */}

      <div className="md:px-52 px-15 mt-24 mb-24">
        <p className="text-white text-center text-3xl mb-10 sm:mb-20 sm:text-5xl">Clients</p>
        <div className="w-full inline-flex rounded-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
          <ul className="flex items-center justify-center md:justify-start animate-infinite-scroll">
            <li>
              <img
                className="mx-44 w-48 h-48 object-contain"
                src="images/atomicmaps.png"
                alt="Atomic Maps"
              />
            </li>
            <li>
              <img
                className="mx-44 w-48 h-48 object-contain"
                src="images/esri3.png"
                alt="Esri"
              />
            </li>
            <li>
              <img
                className="mx-44 w-48  object-contain bg-white rounded-3xl p-4"
                src="images/geodyn.png"
                alt="Geodyn"
              />
            </li>
            <li>
              <img
                className="mx-44 w-48 scale-125  object-contain "
                src="images/aeriellogo.png"
                alt="Geodyn"
              />
            </li>
           
          </ul>
          <ul className="flex items-center justify-center md:justify-start animate-infinite-scroll" aria-hidden="true">
            <li>
              <img
                className="mx-44 w-48 h-48 object-contain"
                src="images/atomicmaps.png"
                alt="Atomic Maps"
              />
            </li>
            <li>
              <img
                className="mx-44 w-48 h-48 object-contain"
                src="images/esri3.png"
                alt="Esri"
              />
            </li>
            <li>
              <img
                className="mx-44 w-48  object-contain bg-white rounded-3xl p-4"
                src="images/geodyn.png"
                alt="Geodyn"
              />
            </li> <li>
              <img
                className="mx-44 w-48  scale-125 object-contain "
                src="images/aeriellogo.png"
                alt="Geodyn"
              />
            </li>
            
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
