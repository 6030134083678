import React, { useState } from "react";
import {  FaChevronRight, FaChevronLeft } from "react-icons/fa";

const TCard = () => {
  const data =
    [
      {
        "name": "Jim Young",
        "comment": "Working with GJ Map Solutions has been an outstanding experience. Their expertise in front-end JavaScript development, python scripting and Esri integration significantly enhanced our geospatial imagery management system. Kushagra, in particular, was instrumental in visualizing Oriented Imagery, as well as full motion video. He created custom front-end client experiences that were both smooth and highly performant. He consistently delivered on time and had an exceptional ability to intuitively understand and anticipate our needs. I highly recommend GJ Map Solutions for anyone looking for top-notch development and integration work.",
        "role": "Managing Director",
        "imageSrc": "atomicmaps.png"
      },
      {
        "name": "Roger Thomas",
        "comment": "We had the pleasure of working with GJ MAP SOLUTIONS on several projects, including the PromptGeoreferencing web app, which they completed within two weeks. Their performance was commendable, demonstrating a high degree of professionalism and technical expertise. They quickly grasped the complexities of the project and delivered a robust solution. The collaboration with our team was seamless and highly efficient. We would recommend GJ MAP SOLUTIONS to others for GIS-related software development needs.",
        "role": "General Manager",
        "imageSrc": "geodyn4.png"
      },
      {
        "name": "Suzi Shoemaker",
        "comment": "GJ Map Solutions has been an indispensable technical partner for our company over the past several years. Throughout this time, Kushagra Patel has consistently demonstrated his ability to deliver well-conceived products with remarkable speed and efficiency. His deep understanding of GIS and the needs of GIS users has been critical in shaping our product design and feature development, particularly in the advancement of our Oriented Imagery offerings such as XPGEO Studio and XPGEO Multi-Viewer. Kushagra's expertise in advanced technologies, coupled with his clear and proactive communication throughout the development process, has ensured smooth project execution and transparency around scope adjustments and other necessary changes. His contributions have been invaluable, and we look forward to continuing our relationship.",
        "role": "President & Chief Operations Officer",
        "imageSrc": "aeriellogo.png"
      },
      {
        "name": "Anonymous",
        "comment": "We have been working with Kushagra and his team for over three years on customer facing apps and maps, to demonstrate user value propositions. The GJ Map Solutions team are always ready to dive into the details and advance our requirements to develop the applications. They have an excellent command of UI/UX design and are especially knowledgeable on GIS software and applications. We would not hesitate to recommend Kushagra and the GJ Map Solutions team for any project that leverages maps to tell stories to B2B users and deliver business value.",
        "role": "Data Provider",
        "imageSrc": "anon.svg"
      }
    ]

  const [idx, setIdx] = useState(0);



  const numOfTestimonials = data.length;

  const handleNext = () => {
    setIdx((prevIdx) => (prevIdx + 1) % numOfTestimonials);
  };

  const handlePrev = () => {
    setIdx((prevIdx) => (prevIdx - 1 + numOfTestimonials) % numOfTestimonials);
  };

  return (
    <div className="flex flex-col items-center mt-24 mb-20 sm:mb-0 bg-black">
      <h1 className="text-3xl sm:text-5xl md:mb-4 text-white">Testimonials</h1>
      <p className="text-center text-lg md:text-xl mb-20  text-gray-500">
        Read what our clients have to say about our services
      </p>
      <div className="relative w-full max-w-4xl h-[60vh] flex justify-center items-center ">
        {data.map((item, index) => {
          let positionClass =
            "absolute flex flex-col items-center justify-start bg-white   rounded-3xl shadow-lg transition-transform duration-500 ease-in-out";

          let widthClass = "w-5/6 sm:w-4/5 md:w-3/4";
          let heightClass = "min-h-[350px]";

          let visibilityClass = " scale-90 opacity-0";

          if (index === idx) {
            visibilityClass =
              "opacity-100 scale-105 z-10 hover:scale-105 transition-transform duration-300 ease-in-out transform hover:shadow-lg rounded-3xl overflow-hidden";
          } else if (
            index ===
            (idx - 1 + numOfTestimonials) % numOfTestimonials
          ) {
            visibilityClass = " opacity-40 -translate-x-1/2 scale-90 z-0";
          } else if (index === (idx + 1) % numOfTestimonials) {
            visibilityClass = " opacity-40 translate-x-1/2 scale-90 z-0";
          }

          return (
            <div
              key={index}
              className={`${positionClass} ${widthClass} ${heightClass} ${visibilityClass}  `}
            >
              <div className="w-full flex flex-row items-center p-4">
                <div className="bg-black rounded-3xl overflow-hidden w-[100px] h-[100px] md:w-[150px] md:h-[150px] shadow-xl flex-shrink-0">
                  <img
                    className="w-full h-full transform-transition duration-300 hover:scale-105 object-contain shadow-3xl p-4"
                    src={`images/${item.imageSrc}`}
                    alt={item.name}
                  />
                </div>
                <div className="ml-4 flex flex-col justify-center text-left">
                  <p className="text-xl sm:text-4xl font-bold text-black">
                    {item.name}
                  </p>
                  <p className="text-base sm:text-lg text-gray-900">
                    {item.role}
                  </p>
                </div>
                <div className="absolute right-12 invisible md:visible">
                  <img
                    src="images/quote.png"
                    alt="Quote"
                    className="w-24 h-24"
                  />
                </div>
              </div>
              <div className="mt-4 px-4 sm:px-6 pb-4 text-center">
                <p className="text-base md:text-base text-black text-justify leading-relaxed">
                  {item.comment}
                </p>
              </div>
            </div>
          );
        })}
        <button
          onClick={handlePrev}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white p-2 rounded-full text-3xl z-20 transform-transition duration-300 hover:scale-125"
        >
          <FaChevronLeft size={32} />
        </button>
        <button
          onClick={handleNext}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white p-2 rounded-full text-3xl z-20 transform-transition duration-300 hover:scale-125"
        >
          <FaChevronRight size={32} />
        </button>
      </div>
    </div>
  );
};

export default TCard;

