import React from "react";
import Header from "../molecules/Header";
import Footer from "../molecules/Footer";
// ‍import { Helmet } from 'react-helmet'
import { FaArrowRight } from "react-icons/fa";

const Services = () => {
  
  const scrollToImage = () => {
    const imageSection = document.getElementById("imageSection");
    if (imageSection) {
      window.scrollTo({
        top: imageSection.offsetTop,
        behavior: "smooth",
      });
    }
  };

  let maxIndex = 7;
  const DetailsCard = ({ index, image, imgAltName, title, description, }) => (
    <div id={index} className="bg-black md:px-8 py-20">
      <div className="container mx-auto flex flex-col md:flex-row gap-8">
        <div className="flex-1 bg-black p-8 rounded-lg shadow-lg flex flex-col">
          <h1 className="animated-gradient-text text-3xl md:pb-4 md:text-5xl font-semibold mb-4">
            {title}
          </h1>
          <p className="text-white text-base md:text-lg sm:w-5/6 text-justify">
            {description}
          </p>
        </div>
        <div className="flex-1 flex items-center justify-center">
          <img src={`images/${image}`} alt={`${imgAltName}`} className="px-8 w-full h-auto max-w-lg rounded-2xl shadow-lg"/>
        </div>
      </div>
    </div>
  );

  const services = [
    {
      title: `Web Application Development`,
      image: `website.svg`,
      description: `Leverage our expertise in ArcGIS to develop dynamic web applications that integrate seamlessly with GIS. We design applications to handle complex spatial data, offering user-friendly interfaces and advanced functionalities that enhance decision-making and operational efficiency for the users.`,
    },
    {
      title: `Custom Software Development`,
      image: "software.svg",
      description: `Our custom software solutions are crafted to meet the specific needs of your GIS projects. Utilizing ESRI’s latest tools, we develop applications that streamline your workflows, enhance data management, and ensure precise alignment with your business objectives. From GIS platforms to data analytics systems, we provide tailored solutions that optimize your operations.`,
    },
    {
      title: `GIS Consulting`,
      image: "gis-consulting.svg",
      description: `With unmatched expertise in ESRI technology and oriented imagery, our consulting services help you unlock the full potential of your spatial data. We offer strategic guidance and customized solutions to improve your GIS workflows, maximize the value of your data, and drive smarter decision-making across your organization.`,
    },
    {
      title: `Mobile Application Development`,
      image: "mobile-development.svg",
      description: `Our mobile application development services bring the power of GIS to your fingertips. We create intuitive, feature-rich mobile apps that integrate with your existing GIS infrastructure, enabling real-time data access and analysis on the go. Whether for field data collection or remote sensing, our solutions are designed to enhance your operational efficiency.`,
    },
    {
      title: `API Development and Software Integration`,
      image: "solution.svg",
      description: `Enable seamless communication between your GIS systems with our API development and software integration services. We develop custom APIs that facilitate data flow, support advanced analytics, and integrate with your existing GIS tools, ensuring that your technology stack is cohesive and efficient.`,
    },
    {
      title: `Application Migration to Cloud`,
      image: "cloud.svg",
      description: `Transition your GIS applications to the cloud with our migration services. We ensure that your spatial data and applications are moved to cloud environments securely and efficiently, taking full advantage of the scalability, flexibility, and performance benefits of cloud computing.`,
    },
    {
      title: `Quality Assurance and Testing`,
      image: "testing.svg",
      description: `Our rigorous quality assurance and testing processes ensure that your GIS software meets the highest standards of performance, security, and usability. We conduct thorough evaluations to identify and resolve potential issues, ensuring that your applications deliver reliable, accurate, and seamless experiences.`,
    },
  ];
  
  

  const scrollToIndex = (e, index) => {
    const doc = document.getElementById(`${index}`);
    if (doc) document.getElementById(`${index}`).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="bg-black  ">

      <Header />
      <div className="bg-black min-h-screen flex flex-col justify-center items-center overflow-x-hidden">
        <div className="relative w-full h-screen">
          <img
            src="images/usa.jpg"
            alt="night-earth"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <h1 className="text-white text-center text-3xl md:text-4xl lg:text-5xl font-bold p-4">
              <span className="text-primary-blue">One Stop Solution</span> For All
              Your <span className="text-primary-blue">GIS</span> Requirements
            </h1>
          </div>
          <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2">
            <button
              className="text-white animate-bounce text-3xl mt-8"
              onClick={scrollToImage}
            >
              ↓
            </button>
          </div>
        </div>

        <div className="bg-black py-12 w-full" id="imageSection">
          <div className="container mx-auto">
            <div className="flex flex-col mt-12 md:flex-row gap-8">
              <div className="md:w-1/2">
                <h1 className="text-white text-3xl sm:pr-8 px-8 lg:text-6xl mb-6 ">
                  Services
                </h1>
                <h2 className="text-white text-xl sm:pr-8 px-8 md:text-xl">
                  Find Out How Our Comprehensive GIS Services Can Meet Your
                  Unique Needs
                </h2>
                <p className="text-gray-500  text-base md:text-lg mt-10  text-justify sm:pr-8 px-8 rounded-md">
                Our services elevate your spatial data with innovative ArcGIS technology, offering full-fledged solutions such as bespoke GIS development and advanced data analytics. As experts in Oriented Imagery, we provide tailored products alongside consulting services to meet your unique geospatial needs.
                </p>
              </div>
              <div className="md:w-1/2 p-4 ">
                {services.map((service, index) => (
                  <div
                    key={index}
                    onClick={(e) => scrollToIndex(e, index)}
                    className="mb-4 rounded-full transition-shadow transition-transform duration-300 ease-in-out hover:shadow-smooth-blue "
                  >
                    <div className="relative group flex items-center border-[0px] border-gray-800 text-white p-4 rounded-full shadow-lg cursor-pointer transition-all duration-300 ease-in-out ">
                      <div className="flex-grow">
                        <p className="text-xl pl-2">{service.title}</p>
                      </div>
                      <div className="ml-4">
                        <FaArrowRight className="text-2xl group-hover:text-blue-500 transition-colors duration-300" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {services.map((service, index) => (
        <div key={index}>
          <DetailsCard
            index={index}
            title={service.title}
            imgAltName="null"
            image={service.image}
            description={service.description}
          />
        </div>
      ))}
      <Footer />
    </div>
  );
};

export default Services;
