
import React, { useEffect, useState } from "react";
import Header from "../molecules/Header";
import Footer from "../molecules/Footer";
import ContactForm from "../molecules/ContactForm";

import { FaRegCheckCircle } from "react-icons/fa";
import MapView from "@arcgis/core/views/MapView.js";
import Map from "@arcgis/core/Map.js";

const Contact = () => {
  const [map, setMap] = useState(null);
  const [mapview, setMapview] = useState(null);
  useEffect(() => {window.scrollTo(0,0)}, [])
  useEffect(() => {
    if (!map && !mapview) {
      const map = new Map({
        basemap: "topo-vector",
      });

      const view = new MapView({
        container: "map",
        map: map,
        zoom: 16,
        center: [73.739569, 24.578951],
        ui: {
          components: [],
        },
      });
      setMap(map);
      setMapview(view);
    }
  }, []);

  return (
    <div className="">
      <Header />

      <div className="flex flex-col lg:flex-row mt-[70px] h-auto bg-black pt-[50px]">
        <div id="left" className="text-white p-6 lg:p-10 w-full lg:w-1/2 lg:pl-24 flex flex-col">
          <div>
            <h1 className="text-4xl lg:text-6xl">Contact Us</h1>
            <p className="text-gray-500 mt-6 lg:mt-10 text-lg lg:text-justify lg:pr-20">
              To get in touch with us, please complete the form. Our team will
              contact you within 2 to 3 business days.
            </p>
            <p className="text-white mt-6 lg:mt-10 text-lg">
              We address your challenges or needs related to:
            </p>
            <div className="mt-6 lg:mt-10">
              <ul>
                <li className="flex flex-row text-lg items-center">
                  <FaRegCheckCircle
                    size={20}
                    className="text-primary-blue mr-4"
                  />
                  ESRI's ArcGIS Technology
                </li>
                <li className="flex flex-row text-lg items-center mt-3">
                  <FaRegCheckCircle
                    size={20}
                    className="text-primary-blue mr-4"
                  />
                  Custom GIS Solutions Tailored to Your Unique Requirements
                </li>
                <li className="flex flex-row text-lg items-center mt-3">
                  <FaRegCheckCircle
                    size={20}
                    className="text-primary-blue mr-4"
                  />
                  Leveraging Advanced Deep Learning for Superior Analytics
                </li>
                <li className="flex flex-row text-lg items-center mt-3">
                  <FaRegCheckCircle
                    size={20}
                    className="text-primary-blue mr-4"
                  />
                  Flexible Resourcing to Meet the Demands of Any Project
                </li>
                <li className="flex flex-row text-lg items-center mt-3">
                  ... and many more
                </li>
              </ul>
            </div>
          </div>
          <div className="h-[1px] w-full lg:w-5/6 bg-gray-800 mt-[50px]"></div>
          <div
            id="map"
            className="mt-10 mb-10 w-full lg:w-5/6 h-[300px] rounded-xl"
          ></div>
          <p>Headquarters: Udaipur, Rajasthan</p>
          <div className="h-[1px] w-full lg:w-5/6 bg-gray-800 mt-[50px]"></div>
          <div className="mt-10 w-full lg:w-5/6">
            <p className="text-justify">
              “We pride ourselves on delivering solutions that exceed client
              expectations. Trusted by industry leaders, we maintain one of the
              highest client satisfaction rates in the GIS consulting domain.
              Our dedication to quality and excellence ensures that you receive
              the best possible service and results.”
              <br />
            </p>
            <p className="text-end">~ Kushagra Patel, Founder and CEO</p>
          </div>
        </div>

        <div id="right" className="text-white w-full lg:w-1/2  flex-1">
          <ContactForm />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
