import "./App.css";
import { Route, Routes } from "react-router-dom";
import About from "./components/pages/About";
import Home from "./components/pages/Home";
import Careers from "./components/pages/Careers";
import Services from "./components/pages/Services";
import Contact from "./components/pages/Contact";
import Expertise from "./components/pages/Expertise";

function App() {

  return (
    <>
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="careers" element={<Careers />} />
          <Route path="services" element={<Services />} />
          <Route path="company" element={<About />} />
          <Route path="contact" element={<Contact/>}/>
          <Route path="expertise" element={<Expertise/>}/>
          <Route path="*" element={<Home />} />
        </Routes>
    </>
  );
}

export default App;
